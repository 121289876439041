<template>
<div class="wrapper">
    <div class="container">
        <div class="row justify-content-between">
            <div id="personalMeetingStringContainer" class="col col-xs-3 col-md-3 col-lg-2 mt-3 mx-auto active-element">
               <b-collapse id="personalMeetingStringContainerchild" class="shadow clickable">
                   <div style="width: 100%;" @click="setActiveTransportationPersonal()">
                       <h5>Gesamt&shy;emission bei Anreise</h5>
                       <p>Bei einem persönlichen Meeting mit {{ this.$store.state.result.participants }} Teilnehmern und {{ Object.keys(this.$store.getters.journeys).length }} Anreisen werden insgesamt {{ this.$store.getters.personalMeetingEmission }} kg CO<sub>2</sub> durch die Anfahrt erzeugt.</p>
                   </div>
               </b-collapse>
               <a role="button" class="collapsed text-center" v-b-toggle="'personalMeetingStringContainerchild'"></a>
            </div>
            <div v-for="journey in journeysByMode" :id="journey.mode" :key="journey.mode" class="col col-xs-3 col-md-3 col-lg-2 mt-3 mx-auto">
                <b-collapse :id="journey.mode+'child'" style="display:block;" class="shadow clickable" >
                    <div  @click="setActiveTransportationMode(journey.mode)">
                    <h5>{{journey.mode}}</h5>
                    <p v-for="j in journey.journeys" :key="j.id">
                        {{ j.string }}
                    </p>
                    </div>
                </b-collapse>    
                
                <a role="button" class="collapsed text-center" v-b-toggle="journey.mode+'child'"></a>
            </div>
            <div id="webConferenceStringContainer" class="col col-xs-3 col-md-3 col-lg-2 mt-3 mx-auto">
                <b-collapse id="webconferenceStringContainerchild" style="display:block;" class="shadow clickable">
                    <div style="width: 100%;" @click="setActiveTransportationModeWeb()">
                        <h5>Webkonferenz</h5>
                        <p>
                            Geschätzter CO<sub>2</sub>-Ausstoß einer Webkonferenz mit {{ this.$store.state.result.participants }}  Teilnehmern und einer Dauer von  {{ Math.round(this.$store.state.result.duration*100)/100 }} Stunden:  {{ Math.round(this.$store.state.result.webConferenceEmission * 100) /100 }}kg
                        </p>
                    </div>
                    
                </b-collapse>
                <a role="button" class="collapsed text-center" v-b-toggle="'webconferenceStringContainerchild'"></a>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    export default {
        computed: { 
            journeysByMode: function(){
                let journeysRaw = this.$store.state.result.extendedJourneys;
                let journeys = {}

                for (let key in journeysRaw){
                    if (!(journeysRaw[key].mode in journeys)){
                        journeys[journeysRaw[key].mode] = {
                            mode : this.mapModeString(journeysRaw[key].mode),
                            journeys : [journeysRaw[key]]
                        }
                    }
                    else {
                        journeys[journeysRaw[key].mode].journeys.push(journeysRaw[key])
                    }
                }

                return journeys
            }   
        },
        methods: {
            mapModeString: function(travelMode){
                switch(travelMode){
                    case 0:
                        return "Emissionsfrei";
                        
                    case 1:
                        return "PKW/Benzin";
                    
                    case 2:
                        return "PKW/Diesel";

                    case 3:
                        return "PKW/Elektro";
                    
                    case 4:
                        return "PKW/Hybrid";
                    
                    case 5:
                        return "Zug";

                    case 6:
                        return "E-Bike";
                }
            },

            mapModeInt: function(travelModeString){
                switch(travelModeString){
                    case "Emissionsfrei":
                        return 0;
                    case "PKW/Benzin":
                        return 1;
                    case "PKW/Diesel":
                        return 2;
                    case "PKW/Elektro":
                        return 3;

                    case "PKW/Hybrid":
                        return 4;

                    case "Zug":
                        return 5;

                    case "E-Bike":
                        return 6;
                    default:
                        return -1;
                }
            },

            setActiveTransportationMode: function(transportationModeIndex){
                console.log(transportationModeIndex)
                //let str = transportationModeIndex.replace("child", "");
                let mappedMode = this.mapModeInt(transportationModeIndex)
               
                let transportationMode = this.$store.getters.emissionsByTransportationMode

                let mode = transportationMode.find(mode => mode.label === mappedMode)
                

                this.setElementActive(transportationModeIndex)

                this.$store.dispatch('activeEmissionMode', mode.emission)

                this.$store.dispatch('setMeetingModePersonal', {meetingMode: 'personal', travelMode: transportationModeIndex})
            },

            setActiveTransportationModeWeb: function(){
                console.log(this.$store.getters.webConferenceEmission)
                this.$store.dispatch('activeEmissionMode', this.$store.getters.webConferenceEmission);
                this.$store.dispatch('setMeetingModeWeb')
                this.setElementActive("webConferenceStringContainer")
            },

            setActiveTransportationPersonal: function(){
                this.$store.dispatch("activeEmissionMode", this.$store.getters.personalMeetingEmission)
                this.$store.dispatch('setMeetingModePersonal', {meetingMode: 'personal', travelMode: null});
                this.setElementActive("personalMeetingStringContainer")
            },

            setElementActive: function(elementId){
                let activeElements = document.getElementsByClassName("active-element")
                activeElements.forEach(element => element.classList.remove("active-element"))
                let element = document.getElementById(elementId)
                element.classList.add("active-element")

            }
        }
    }

</script>


<style scoped>
    #personalMeetingStringContainerchild{
        padding:10px;
    }
    #string-result-container {
        margin-top: 50px;
        margin-bottom: 50px;
       
        text-align: left;
    }
    .shadow {
        /* padding-top: 10px;
        margin-left:15px;
        margin-right: 15px; */
        box-shadow: #0e0e0e0e;
        background-color: #FDFFFE;
        border-radius: 5px;
    }

    .wrapper{
        padding-top: 30px;
        padding-bottom: 75px;
        /* background-color: #F8FFFD */
    }

    div.collapse{
        width:100%;
    }

    div.collapse:not(.show){
        display:block !important;
        height: 8rem !important;
        overflow: hidden;
        width:100%;
    }

     #Emissionsfrei.collapsing, #PKW\/Benzin.collapsing, #PKW\/Diesel.collapsing, #PWK\/Elektro.collapsing, #PKW\/Hybrid.collapsing, #Zug.collapsing, #E-Bike.collapsing{ 
        height: 8rem ;
        width:100%;
        display: block;
        
    }
    .collapsing.active-element, .collapse:not(.show).active-element, .collapse.active-element{
        background-color:#E6FFF9;
    }

    .collapsed.active-element > when-open
    {
        background-color:#E6FFF9;
    }

    .active-element > :first-child{
        background-color: #E6FFF9;
        
    }
    a.collapsed{
       box-sizing: border-box;
    }

    a.collapsed::after{
        width:100%;
        display:inline-block;
        content: "[...]";
        position: relative;
        bottom: 3em;
        background-image: linear-gradient(to bottom, transparent, #ffffff);
        padding-top:1rem;
        padding-bottom:.5rem;
        
    }
    a:not(.collapsed)::after{
        position:static;
        content: "[weniger]";
    }

    .active-element > a.collapsed::after{
        background-image: linear-gradient(to bottom, transparent, #E6FFF9);
    }
    
</style>