<template>
    <div class="col col-xs-4 col-lg-4 align-sef-center" style="padding-left:35px; padding-right:35px;">
        <div class="center-image">
            <img src="../assets/img/flugzeug.png" height="300px"/>
        </div>
        <div >
            <h5>Flugreisen</h5>
            
            <p>{{ planeArrayIndex }}.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "Plane",
    computed: {
        planeArrayIndex: function(){
            let emission = this.$store.getters.personalMeetingEmission;
            let flight;
            let flightEmissionPercentage;
            
            if (emission < 122){
                flight = 104;
                flightEmissionPercentage = Math.round((emission/flight) * 100 * 100)/100;
                return ` Der CO\u2082-Ausstoß dieses Meetings entspricht ${flightEmissionPercentage}% eines voll besetzten Fluges von Frankfurt nach München`;
            }
            else if(emission < 156 ) {
                flight = 122;
                flightEmissionPercentage = Math.round((emission/flight) * 100 * 100)/100;
                return ` Der CO\u2082-Ausstoß dieses Meetings entspricht ${flightEmissionPercentage}% eines voll besetzten Fluges von München nach Berlin`;
            }
            else if (emission < 260){
                flight = 156;
                flightEmissionPercentage = Math.round((emission/flight) * 100 * 100)/100;
                return ` Der CO\u2082-Ausstoß dieses Meetings entspricht ${flightEmissionPercentage}% eines voll besetzten Fluges von Düsseldorf nach London`;
            }
            else if (emission < 270){
                flight = 260;
                flightEmissionPercentage = Math.round((emission/flight) * 100 * 100)/100;
                return ` Der CO\u2082-Ausstoß dieses Meetings entspricht ${flightEmissionPercentage}% eines voll besetzten Fluges von Düsseldorf nach Mallorca`;
            }
            else if (emission < 500){
                flight = 270;
                flightEmissionPercentage = Math.round((emission/flight) * 100 * 100)/100;
                return ` Der CO\u2082-Ausstoß dieses Meetings entspricht ${flightEmissionPercentage}% eines voll besetzten Fluges von München nach Athen`;
            }
            else if (emission < 1636) {
                flight = 500;
                flightEmissionPercentage = Math.round((emission/flight) * 100 * 100)/100;
                return ` Der CO\u2082-Ausstoß dieses Meetings entspricht ${flightEmissionPercentage}% eines voll besetzten Fluges von Frankfurt nach Teneriffa`;
            }
            else if (emission < 1829 ){
                flight = 1636;
                flightEmissionPercentage = Math.round((emission/flight) * 100 * 100)/100;
                return ` Der CO\u2082-Ausstoß dieses Meetings entspricht ${flightEmissionPercentage}% eines voll besetzten Fluges von Frankfurt nach Vancouver`;
            }
            else if (emission < 2534){
                flight = 1829;
                flightEmissionPercentage = Math.round((emission/flight) * 100 * 100)/100;
                return ` Der CO\u2082-Ausstoß dieses Meetings entspricht ${flightEmissionPercentage}% eines voll besetzten Fluges von Frankfurt nach Sydney (via Dubai)`;
            }
            else {
                flight = 2534;
                flightEmissionPercentage = Math.round((emission/flight) * 100 * 100)/100;
                return ` Der CO\u2082-Ausstoß dieses Meetings entspricht ${flightEmissionPercentage}% eines voll besetzten Fluges von München nach New York`;
            }
        }
    }
}
</script>

<style scoped>
.center-image{
     width:inherit;
     text-align: center;
 }
 .padding{
     padding: 15px;
 }
</style>