// import { EmissionCalculator } from './emission-calcualtor';

const Chart = require('chart.js')

const RED = 'rgba(256,0,0,0.2)';
const YELLOW = 'rgba(0,256,0,0.2)';
const BLUE = 'rgba(0,0,256,0.2)';
const GREEN = 'rgba(0,256,256.0.2)';

const COLORS = [RED, YELLOW, BLUE, GREEN]

function buildChartDataObject(emissionsByType, webconferenceEmission){
    let resultObject = {
        labels: ['Persönliche Anreise', "Videokonferenz"],
        datasets: [],
        
        }
    console.log(emissionsByType.emissions)
    for ( let i=0;i<emissionsByType.length;i++){ // TODO: updateChart mit den richtigen Werten aufrufen
        // if(emissionsByType.emissions[i] === 0){
        //     continue;
        // }
        let modeString = mapTravelModeToString(emissionsByType[i].label)
        console.log("Modus: ", modeString)
        console.log(emissionsByType[i])
        resultObject.datasets.push({
            label: modeString,
            backgroundColor: COLORS[i % (COLORS.length)],
            data: [
                emissionsByType[i].emission,
                0
            ]
        })
    }

    resultObject.datasets.push({
        label: 'Videokonferenz',
        backgroundColor: GREEN,
        data: [0,webconferenceEmission]
    })

    return resultObject
}

export function drawChart(personalMeetingEmission, webConferenceEmission, chartId){
    console.log("Chart's ID", chartId)
    const ctx = document.getElementById(chartId).getContext("2d");

    var barChartData = buildChartDataObject(personalMeetingEmission,webConferenceEmission)//{
    //     labels: ['Perönliche Anreise', 'Videokonferenz'],
    //     datasets: [{
    //         label: 'Emissionsfrei',
    //         backgroundColor: 'rgba(256,10,33)',
    //         data: [
    //             0,
    //             0
    //         ]
    //     }, {
    //         label: 'PKW / Benzin',
    //         backgroundColor: 'rgba(128,22,3)',
    //         data: [
    //             1.23,
    //             0
    //         ]
    //     }, {
    //         label: 'Bahn',
    //         backgroundColor: 'rgba(39,2,111)',
    //         data: [
    //             14,
    //             0
    //         ]
    //     },
    //     {
    //         label: 'Videokonferenz',
    //         backgroundColor: 'rgba(25,37,128)',
    //         data: [
    //             0,
    //             12
    //         ]
    //     }]

    // };
    return new Chart(ctx, {
        type: 'bar',
        data: barChartData,//{
        //     labels: ['Anreise', 'Videokonferenz'],
        //     datasets: [{
        //         label: 'CO\u2082-Emission in kg',
        //         data:[personalMeetingEmission, webConferenceEmission],
        //         backgroundColor: [
        //             'rgba(255,99,132,0.2)',
        //             'rgba(54,162,235,0.4)'
        //         ],
        //         borderColor: [
        //             'rgba(255,99,132,1)',
        //             'rgba(54,162,235,1)'
        //         ],
        //         borderWidth: 1
        //     }]
        // },
        options: {
            tooltips: {
                callbacks:{
                    label: (item) => `${item.yLabel} kg`
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: "CO\u2082-Emission in kg"
                    },
                    stacked: true,
                    ticks: {
                        beginAtZero: true
                    },
                
                }],
                xAxes: [{
                    stacked: true
                }]
            }
        }
    });
}

function mapTravelModeToString(travelMode){
    switch(travelMode){
        case 0:
            return "Emissionsfrei";
            
        case 1:
            return "PKW/Benzin";
        
        case 2:
            return "PKW/Diesel";

        case 3:
            return "PKW/Elektro";
        
        case 4:
            return "PKW/Hybrid";
        
        case 5:
            return "Zug";

        case 6:
            return "E-Bike";
    }
}