<template>
    <div id="meeting">
        <div style="margin-left: 10px">
            <h2 class="tab-content-header">Meeting</h2>
        </div>
        <EventInput/>
        <div class="journeys-container container">
            <div class="row justify-content-between">
                <div class="col col-xs-5 col-sm-5 col-md-5 col-lg-5 mx-auto row-label"><label>Art der Anreise</label></div>
                <div class="col col-xs-3 col-sm-3 col-md-3 col-lg-3 mx-auto row-label"><label>Personen im Fahrzeug</label></div>
                <div class="col col-xs-3 col-sm-3 col-md-3 col-lg-3 mx-auto row-label"><label>Zurückgelegte Strecke in km</label></div>
                <div class="col col-xs-1 col-sm-1 col-md-1 col-lg-1 mx-auto row-label"></div>
            </div>
            <div v-for="journey in journeys" :key="journey.id" class="row justify-content-between">
                <div class="col col-xs-5 col-sm-5 col-md-5 col-lg-5 mx-auto">
                            <div class="form-group">
                                <select class="form-select" v-model="journey.mode" @change="setInputChangedTrue"> <!-- this is a terrible idea -->
                                    <option value=0>Emissionsfrei</option>
                                    <option value=1>PKW / Benzin </option>
                                    <option value=2> PKW / Diesel </option>
                                    <option value=3> PKW / Elektro </option>
                                    <option value=4> PKW / Hybrid </option>
                                    <option value=5> Zug </option>
                                    <!-- <option value=6>E-Bike</option> -->
                                </select>
                            </div>
                        </div>
                        <div class="col col-xs-3 col-sm-3 col-md-3 col-lg-3 mx-auto">
                            <div class="form-group">
                                <input class="form-control" placeholder="Personen im Fahrzeug" type="number" v-model="journey.travellers" min="1" @change="setInputChangedTrue"/>
                            </div>
                        </div>
                        <div class="col col-xs-3 col-sm-3 col-md-3 col-lg-3 mx-auto">
                            <div class="form-group">
                                <input class="form-control" placeholder="Gefahrene Strecke (Hin- und Rückfahrt) in km" type="number" v-model="journey.distance" min="1" @change="setInputChangedTrue"/>
                            </div>
                        </div>
                        <div class="col col-xs-1 col-sm-1 col-md-1 col-lg-1 mx-auto">
                            <div class="form-group">
                                <button class="btn btn-close" @click="removeJourney(journey.id)"></button>
                            </div>
                        </div>
                </div>
                <div>
                    <button class="btn btn-plus" @click="addJourney"><svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em"  class="bi bi-plus" viewBox="0 0 16 16">
  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
</svg></button>
                    <!-- TODO: This button should not be part of the meeting. Also style differently to distinguish from the meeting-related input -->
                </div>
                <div class="text-center" style="margin-top:25px;">
                     <button class="btn btn-primary" @click="calculateMeetingResult">Ergebnis</button>
                </div>
        </div>
    </div>
</template>

<script>
import EventInput from './EventInput';
export default {
    name: 'Meeting',
    components: {
        EventInput
    },
    data: function(){
        return {
            inputChanged: false
        }
    },
    computed: {
        journeys(){
            return this.$store.getters.journeys;
        }
    },
    // TODO: addJOurney and removeJourney use redundant code
    methods: {
        addJourney: function(){
            this.$store.dispatch('addJourney');
            if(this.$store.getters.getChart){
                this.updateJourney(-1)
            }
        },
        removeJourney: function(id){
            this.$store.dispatch('removeJourney', id);
            if(this.$store.getters.getChart){
                this.updateJourney(-1)
            }
        },
        calculateMeetingResult:function(){
            if(this.$store.state.meetingResultCalculated){
                let personal = document.getElementById("personalMeetingStringContainerchild");
                let clickables = Array.from(document.getElementsByClassName("clickable"));
                clickables.forEach(element => {
                    element.classList.remove('activeElement');
                });
                personal.classList.add("activeElement");
            }
            this.$store.dispatch(
                {type:'calculateMeetingResult',
                journeys: this.journeys,
                chartName: "meetingChart"});
            this.inputChanged = false;
            
        },
        setInputChangedTrue: function(){
            this.inputChanged = true
        },
        reevaluate: function(){
            if(this.inputChanged === true){
                console.log("Test if reevaluate function gets called")
                this.calculateMeetingResult();
                this.inputChanged = false;
                
            }
        },
        
    }
}
</script>

<style scoped>
/* Das ist neu 02.05.2021 */
    #meeting{
        border-radius: 5px;
        
        background-color:#F8F9FA;
        /* background-color: #DBF3F9; */
        padding-top: 35px;
        padding-bottom: 25px;
        padding-right: 10px;
        padding-left:5px;
    }
    /* Entfernt 02.05.2021 */
    /* .tab-content-header {
        margin-top: 35px;
    } */
    .shadow{
        box-shadow: #0e0e0e0e;
    }
    .row-label {
        text-align: left;
    }


    .journeys-container {
        margin-top: 40px;
        /* margin-bottom: 50px; */
        margin-left: 0px;
        
    }
    /* button {
        margin-left: 15px;
        margin-right: 15px;
        margin-top:20px;
    } */

    .btn-primary{
        background-color: #6FB1C1;
        border-color: #6FB1C1;
    }
    .btn-primary:hover{
        background-color: #385961;
        border-color: #385961
    }

    .bi-plus{
        fill:rgb(128, 128, 128)
    }

    .bi-plus:hover,.bi-plus:active{
        fill:#404040;
    }
    button.btn{
        border:none;
        box-shadow: none;
    }
    button.btn.btn-plus{
        border:none;
        box-shadow: none;
    }

    /* Taken from Bootstrap 5 */
    .btn-close{
        box-sizing:content-box;
        width:1em;
        height:1em;
        padding:.25em .25em;
        color:#000;
        background:transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
        border:0;
        border-radius:.25rem;
        opacity:.5
        }
    .btn-close:hover{color:#000;text-decoration:none;opacity:.75}
    .btn-close:focus{outline:0;box-shadow:0 0 0 .25rem rgba(13,110,253,.25);opacity:1}
    .btn-close.disabled,.btn-close:disabled{pointer-events:none;-webkit-user-select:none;-moz-user-select:none;user-select:none;opacity:.25}
    .btn-close-white{filter:invert(1) grayscale(100%) brightness(200%)}
    .btn-close{margin-right:-.375rem;margin-left:.75rem}
    .btn-close{padding:.5rem .5rem;margin:-.5rem -.5rem -.5rem auto}
    
    .form-select{display:block;width:100%;padding:.375rem 1.75rem .375rem .75rem;font-size:1rem;font-weight:400;line-height:1.5;color:#212529;background-color:#fff;background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");background-repeat:no-repeat;background-position:right .75rem center;background-size:16px 12px;border:1px solid #ced4da;border-radius:.25rem;-webkit-appearance:none;-moz-appearance:none;appearance:none}
    .form-select:focus{border-color:#86b7fe;outline:0;box-shadow:0 0 0 .25rem rgba(13,110,253,.25)}
    .form-select[multiple],.form-select[size]:not([size="1"]){padding-right:.75rem;background-image:none}.form-select:disabled{color:#6c757d;background-color:#e9ecef}
    .form-select:-moz-focusring{color:transparent;text-shadow:0 0 0 #212529}
    .form-select-sm{padding-top:.25rem;padding-bottom:.25rem;padding-left:.5rem;font-size:.875rem}
    .form-select-lg{padding-top:.5rem;padding-bottom:.5rem;padding-left:1rem;font-size:1.25rem}
</style>