/*
    Q: Why?
    A: Because a Date turned into an ISOSTring is needed to display in datatime-local inputs
       However, the ISOString given by date.toISOString is not acceptetd an must spliced.
       In this process, the timezone information gets lost, the date is one hour earlier than the selected value
*/
export function adjustTime(now = new Date()){
    if (typeof now !== Date()){
        now = new Date(now)
    }
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    console.log(now)
    return now.toISOString().slice(0,16)
}