<template>
    <section class="bg-light">
        <div class="container"  style="width:100%">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <h2 class="section-heading text-uppercase">So werden die Ergebnisse berechnet</h2>
                    
                </div>
            </div>
           <div class="row justify-content-between">
                <div class="col-md-3 col-sm-6 mt-3 shadow">
                    <h5>Anreisen mit Verbrennern</h5>
                    <br/>
                    <div>
                        <p style="font-weight: bold;">Benziner</p>
                        <p>Ausgegangen wird von 2,3kg CO<sub>2</sub> pro Liter Benzin bei einem durchschnittlichen Verbrauch von 7l pro 100 km.</p>
                    </div>
                    <div>
                        <p style="font-weight: bold;">Diesel</p>
                        <p>Ausgegangen wird von 2,6 kg CO<sub>2</sub> pro Liter Diesel bei einem durchschnittlichen Verbrauch von 7l pro 100 km.</p>
                    </div>
                </div>
                <div class="col-md-3 col-sm-6 mt-3 shadow">
                    <h5>Anreise mit alternativen Antrieben</h5>
                    <div>
                        <p style="font-weight: bold;">Hybrid</p>
                        <p>Ausgegangen wird von einem durchschnittlichen CO<sub>2</sub>-Ausstoß von 0,175 kg pro km.</p>
                    </div>
                    <br/>
                    <div>
                        <p style="font-weight: bold;">Elektro</p>
                        <p>Ausgegangen wird von 15 kW/h pro 100 km bei einem CO<sub>2</sub>-Ausstoß von 401 g pro kW/h. [Stand 2019]</p>
                    </div>
                </div>
                <div class="col-md-3 col-sm-6 mt-3 shadow">
                    <h5>Öffentliche Verkehrsmittel</h5>
                    <br/>
                    <div>
                        <p style="font-weight: bold;">Zug</p>
                        <p>
                            Für die Anreise mit dem Zug wird pro Person von einem CO<sub>2</sub>-Ausstoß von 0,041 kg pro Kilometer ausgegangen.
                        </p>
                    </div>
                </div>
                <!-- <div class="col-md-3 col-sm-6 shadow">
                    <h5>Webkonferenz</h5>
                    <br/>
                    <div>
                        <p style="font-weight: bold;">Bahn</p>
                        <p>
                            Bei der Berechnung des CO<sub>2</sub>-Ausstoßes von Webkonferenzen wird von einem Stromverbrauch von 0,2 kW/h und einer CO<sub>2</sub>-Emission von 401g pro kW/h ausgegangen.
                        </p>
                    </div>
                </div> -->
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Explain'
}
</script>

<style scoped>
    section {
        padding: 50px;
        /* background-color: #ffffff; */
    }
    .shadow {
        box-shadow: #0e0e0e0e;
        background-color: #ffffff;
        padding-top: 15px;
        border-radius: 5px;
        margin: 15px;
    }

    h2{
        padding-bottom: 50px
    }

    /* .container {
        max-width: 80vw;
    } */
        
    
</style>