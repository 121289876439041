<template>
  <div class="align-self-center text-center">
      <h1>CO<sub>2</sub>-Veranstaltungsrechner</h1>
  </div>
</template>

<script>
export default {
    name: "Title"
}
</script>

<style scoped>
    div{
        margin-top: 70px;
        margin-bottom:55px;
        /* font-family: 'Kaushan Script', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'; */
    }
</style>