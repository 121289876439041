<template>
  <div class="wrapper">
    <div class="container">
        <div class="row justify-content-between">
               
            <Plane style="text-align: justify" />
            <VisualizationPeople style="text-align: justify" />
            <div class="col col-xs-4 col-lg-4" style="padding-left:35px;padding-right:35px;">
                <div class="center-image">
                    <img src="../assets/img/windrad.png" alt="An image of a windmill" height="300px" />
                </div>
                <div style="text-align: justify">
                    <h5>Erneuerbare Energie</h5>
                    <p>Sowohl für die Anreise in Fahrzeugen mit elektrischen Antrieben, als auch für Videokonferenzen wird elektrischer Strom benötigt.</p>
               <p>In Deutschland stammen bereits 45,4% des produzierten  elektrischen Stroms aus erneuerbaren Energien.</p>
                </div>
          
            </div>
        </div>
    </div>
  </div>
</template> 

<script>
import VisualizationPeople from './VisualizationPeople';
import Plane from './Plane';
export default {
    name: "FooterResult",
    components: {
        VisualizationPeople,
        Plane,
    }

}
</script>

<style scoped>
 .shadow {
     box-shadow: lavenderblush;
 }
 .wrapper{
     background-color: #F8FFFD;
     padding-bottom: 100px;
     padding-top: 75px;
 }

 .center-image{
     width:inherit;
     text-align: center;
 }

 p{
     text-align:justify;
 }
</style>