<template>
    <div class="event-input">
        <div class="input-container form-group">
            <label for="event-name">Name der Veranstaltung</label>
            <input type="text" v-model="eventName" class="form-control" placeholder="Bitte den Namen der Veranstaltung eingeben"/><!--id removed, unsure if needed by vue-->
        </div>
        <div class="input-container form-group">
            <div class="row">
                <div class="col col-xs-6 col-sm-6 col-ml-6 col-lg-6">
                    <label for="start-time">Beginn der Veranstaltung</label>
                    <input id="start-time" type="datetime-local" v-model="startTime" class="form-control"/>
                </div>
                <div class="col col-xs-6 col-sm-6 col-ml-6 col-lg-6">
                    <label for="end-time">Ende der Veranstaltung</label>
            <input id="end-time" type="datetime-local" v-model="endTime" class="form-control" @blur="evaluateEndDate"/>
                </div>
            </div>
            
        </div>
        <div class="input-container form-group">
            
        </div>
    </div>
</template>

<script>
export default {
    name:'EventInput',
    computed:{
        startTime: {
            get: function(){
                return this.$store.getters.startTime
            },
            set: function(value){
                this.$store.commit('setStartTime', value)
            }
        },
        endTime: {
            get:  function(){
            return this.$store.getters.endTime
            },
            set: function(value){
                this.$store.commit('setEndTime', value)
            }
        },
        eventName: {
            get: function(){
                return this.$store.getters.eventName;
            },
            set: function(value){
                this.$store.commit("setEventName", value)
            }
        }
    },
    methods: 
    {
        evaluateEndDate: function(){
            let endDate = new Date(this.endTime);
            let startDate = new Date(this.startTime);
            //if the time a meeting ends is smaller than the meetings startTime, set endTime to startTime
            //Not really nice, change this to visually show the user the wrong input and prevent results from being calculated
            if (endDate < startDate){
                this.endTime = this.startTime;
            }
        }
    }
}
</script>

<style scoped>
    .input-container {
        margin: 12px 12px 10px 10px;
        text-align: left;
    }

    .event-input {
        text-align: left;
    }
    input {
        margin-top: 0px;
        margin-bottom: 10px;
        margin-left: 0px;
        margin-right:0px;
    }

    label {
        margin-top:15px;
    }
</style>