<template>
    <div class="col col-xs-4 col-lg-4" style="padding-left:35px;padding-right:35px;">
        <div class="center-image">
            <img src="../assets/img/menschen.png" height="300"/>
        </div>
        <div>
            <h5>CO<sub>2</sub>-Ausstoß pro Kopf</h5>
            <div>Die Menge des bei einem persönlichen Treffen durch die Anreise erzeugten CO<sub>2</sub> entspicht: 
                <ul>
                    <li>
                        <i>{{ carbonFootprintGermany }}%</i> des jährlichen CO<sub>2</sub>-Ausstsoßes eines durchschnittlichen Menschen in Deutschland.
                    </li>
                    <li>
                        <i>{{ carbonFootprintIndian }}%</i> des jährlichen CO<sub>2</sub>-Ausstoßes eines durchschnittlichen Menschen in Indien. 
                    </li>
                    <li>
                        <i>{{ carbonFootprintWorld }}%</i> des weltweit durchschnittlich pro Person jährlich produzierten CO<sub>2</sub>.
                    </li>
                </ul>
            </div>
    </div>
  </div>
</template>

<script>
export default {
    name:"VisualizationPeople",
    computed: {
        // TODO: Create Method that calculates emission from parameter, use that function for the three functions here
        carbonFootprintGermany: function(){
            const carbonGerman = 9200;
            
            return Math.round((this.$store.getters.personalMeetingEmission / (carbonGerman)) * 100 * 100) / 100 // First * 100: calculate Percantage, second: shift comma to round
        },
        carbonFootprintIndian: function(){
            const carbonIndian = 1940;
            
            return Math.round((this.$store.getters.personalMeetingEmission / (carbonIndian)) * 100 * 100) / 100;
        },
        carbonFootprintWorld: function(){
            const carbonWorldwide = 4730;
            
            return Math.round(this.$store.getters.personalMeetingEmission / (carbonWorldwide) * 100 * 100) / 100;

        }
    }
}
</script>

<style scoped>
   .container {
       padding: 15px;
   }

   .center-image{
     width:inherit;
     text-align: center;
 }
</style>