<template>
<div class="wrapper">
  <div class="container">
      <div class = "row justify-content-between">
          <div class="col">
            <svg id="svg833" version="1.1" viewBox="0 0 614.4 345.6" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs id="defs837">
                    <radialGradient id="radialGradient2953" cx="284.56" cy="175.21" r="307.2" gradientTransform="matrix(.6272 .00092051 -.0006963 .47441 106.21 90.773)" gradientUnits="userSpaceOnUse">
                    <stop id="stop2945" style="stop-color:#e6fff9;stop-opacity:0" offset="0"/>
                    <stop id="stop2947" style="stop-color:#e6fff9" offset="1"/>
                    </radialGradient>
                </defs>
                <g id="layer3">
                    <rect id="rect896" width="614.4" height="345.6" style="fill:url(#radialGradient2953);mix-blend-mode:normal"/>
                </g>
                <g id="layer1">
                    <ellipse id="path2959" cx="305.31" cy="222.2" rx="131.75" ry="102.36" style="fill:#a4845e"/>
                </g>
                <g id="layer2">
                    <g id="baum1" class="tree">
                        <ellipse id="path2961" cx="188.31" cy="138.42" rx="29.333" ry="49.83" style="fill:#b7c06f;stroke-width:1.4588;stroke:#b7c06f"/>
                    <g id="Tree1" style="fill:#906731;stroke:#906731">
                        <path id="path851" d="m185.47 212.33 3.0027-81.131h0.56301l2.7429 81.116z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                        <path id="path853" d="m190.79 178.46 12.906-19.691-13.137 14.118z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                        <path id="path855" d="m187.03 167.52-21.308-21.856 21.279 17.872z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                        <path id="path857" d="m190.07 151.2 6.8138-4.7639-6.6984 9.2391z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                    </g>
                    </g>
                    <g id="Baum5" class="tree">
                        <ellipse id="path3049" cx="259.27" cy="96.144" rx="29.712" ry="49.865" style="fill:#70c0a5;stroke-width:1.399;stroke:#70c0a5"/>
                    <g id="trunk5" style="fill:#906731;stroke:#906731">
                        <path id="path3033" d="m256.01 170.14h6.2064l-2.6132-81.5h-0.36749z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                        <path id="path3037" d="m261.29 130.82 15.216-17.814-14.927 23.386z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                        <path id="path3039" d="m257.63 125.57-21.337-22.145 21.365 17.901z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                        <path id="path3041" d="m260.66 109.05 6.9871-5.1392-6.9004 9.4701z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                    </g>
                    </g>
                    <g id="baum3" class="tree">
                        <ellipse id="path2997" cx="292.27" cy="158.02" rx="29.527" ry="49.943" style="fill:#a6d59f;stroke-width:1.2332;stroke:#a6d59f"/>
                    <g id="tree2" style="fill:#906731;stroke:#906731">
                        <path id="path865" d="m289.15 232.22h6.5251l-2.8583-81.766h-0.38256z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                        <path id="path867" d="m294.48 192.64 15.006-17.353-14.699 23.151z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                        <path id="path869" d="m291.03 187.4-21.273-21.926 21.253 18.048z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                        <path id="path871" d="m293.74 171.23 7.0638-5.206-6.7372 9.2892z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                    </g>
                    </g>
                    <g id="baum2" class="tree">
                        <ellipse id="path2987" cx="424.08" cy="125.76" rx="29.03" ry="49.69" style="fill:#b7c06f;stroke-width:.92137;stroke:#b7c06f"/>
                    <g id="tree3" style="fill:#906731;stroke:#906731">
                        <path id="path879" d="m420.77 199.54h6.3697l-2.7868-81.648h-0.20415z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                        <path id="path881" d="m426.27 165.61 14.956-22.867-15.014 17.208z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                        <path id="path885" d="m422.52 154.87-21.539-22.347 21.423 18.305z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                        <path id="path887" d="m425.4 138.53 6.8716-5.1392-6.7561 9.3546z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                    </g>
                    </g>
                    <g id="baum4" class="tree">
                        <ellipse id="path3023" cx="324.94" cy="64.514" rx="29.454" ry="49.952" style="fill:#a6d59f;stroke-width:1.3789;stroke:#a6d59f"/>
                    <g id="trunk1" style="fill:#906731;stroke:#906731">
                        <path id="path3007" d="m328.33 138.5h-6.5534l3.1032-81.785h0.58185z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                        <path id="path3009" d="m327.3 98.916 15.187-17.785-15.014 23.213z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                        <path id="path3011" d="m323.37 93.661-21.019-22.174 21.077 18.305z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                        <path id="path3013" d="m326.31 77.089 7.2758-4.7928-6.8716 9.1236z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                    </g>
                    </g>
                    <g id="Baum6" class="tree">
                        <ellipse id="path3075" cx="377.22" cy="207.55" rx="29.638" ry="49.38" style="fill:#70c0a5;stroke-width:1.2975;stroke:#70c0a5"/>
                    <g id="Trunk6" style="fill:#906731;stroke:#906731">
                        <path id="path3061" d="m373.94 281.24h6.4385l-2.8287-81.456h-0.63289z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                        <path id="path3063" d="m379.47 247.28 15.158-23.199-15.36 17.785z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                        <path id="path3065" d="m375.45 236.41-21.337-22.289 21.51 18.247z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                        <path id="path3067" d="m378.37 220.27 7.1603-5.0526-6.7561 9.2102z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                    </g>
                    </g>
                    <g id="Baum7" class="tree">
                        <ellipse id="path3099" cx="255.2" cy="226.98" rx="28.828" ry="49.448" style="fill:#6fc073;stroke-width:2.4676;stroke:#6fc073"/>
                    <g id="trunk7" style="fill:#906731;stroke:#906731">
                        <path id="path3085" d="m251.99 301.07h6.533l-2.8174-81.867h-0.63289z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                        <path id="path3087" d="m257.66 266.78 14.956-22.809-15.129 17.323z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                        <path id="path3089" d="m253.5 256.21-20.846-22.058 20.73 17.728z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                        <path id="path3091" d="m256.83 239.62 6.8572-4.9949-6.7128 9.3113z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                    </g>
                    </g>
                    <g id="Baum8" class="tree">
                        <ellipse id="path3123" cx="327.12" cy="238.62" rx="28.767" ry="49.892" style="fill:#6fb1c1;stroke-width:2.7154;stroke:#6fb1c1"/>
                    <g id="trunk8" style="fill:#906731;stroke:#906731">
                        <path id="path3109" d="m324.08 312.85h6.6147l-1.2249-33.359-1.7149-48.018h-0.38671z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                        <path id="path3111" d="m329.56 273.16 14.938-17.531-14.688 23.25z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                        <path id="path3113" d="m328.75 251.66 6.9688-4.9062-6.6875 9.1875z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                        <path id="path3115" d="m325.88 268.16-21.188-21.906 0.15625-0.0312 20.906 17.938z" style="fill:#906731;stroke-width:1px;stroke:#906731"/>
                    </g>
                    </g>
                </g>
            </svg>
          </div>
          
      </div>
      <div class="row" style="height:8rem;">
          <div class="col">
              <h5>Kompensation durch Bäume</h5>
              <div v-if="meetingMode === 'personal'">
                <p>Der durch eine persönliche Anreise verursachte CO<sub>2</sub>-Ausstoß von <i>{{ Math.round(this.$store.getters.personalMeetingEmission * 100 ) / 100 }}kg</i> entspricht etwa der Menge CO<sub>2</sub>, die von <i>{{ Math.round(this.$store.getters.treesTotalPersonal * 100 ) / 100 }}</i> Bäumen innerhalb eines Jahres gebunden wird.</p>
                <p v-if="travelMode !== null">Davon entfallen {{ Math.round(treesNeeded * 100 ) / 100 }} Bäume auf die Anreiseart "{{travelMode}}"</p>
              </div>
              <div v-else>
                  <p>Der durch eine Webkonferenz verursachte CO<sub>2</sub>-Ausstoß von <i>{{ Math.round(this.$store.getters.webConferenceEmission * 100 ) / 100 }}kg</i> entspricht etwa der Menge CO<sub>2</sub>, die von <i>{{Math.round(this.$store.getters.treesTotalWeb * 100 ) / 100 }}</i> Bäumen innerhalb eines Jahres gebunden wird.</p>
                  <p>Davon entfallen {{(Math.round((this.$store.getters.treesTotalWeb / this.$store.state.result.participants) * 100 ) / 100)}} Bäume auf einen einzelnen Teilnehmer</p>
              </div>
          </div>
      </div>
      </div>
  </div>

</template>

<script>
export default {
    name: "Trees",
    computed : {
        treesNeeded: function(){
            return Math.round((this.$store.getters.activeTransportationModeEmission  / 10 )*100)/100;
        },
        meetingMode: function(){
            return this.$store.getters.meetingMode;
        },
        travelMode: function(){
            return this.$store.getters.travelMode;
        }
    },
    methods:{
        animate: function(treeCount){
            
            let trees = Array.from(document.getElementsByClassName("tree"))//.filter(tree => !tree.classList.contains("animate")); //Trees not yet rendered
            
            const availableTreeCount = trees.length //number of tree elements in the image
            
            trees.forEach(tree => tree.classList.remove("animate")); // reset trees that were already rendered
            
            let n = Math.min(treeCount, availableTreeCount);

            let t = [];

            // select random trees from not yet painted trees
            while (n > 0) {
                let randomNumber = Math.floor(Math.random() * trees.length);

                let tree = trees.splice(randomNumber, 1).pop();

                t.push(tree);

                n--;
            }

            for (let i = 0; i < t.length; i++) {
                setTimeout( () => t[i].classList.add("animate"), i * 300 + 50 * i); // delay the rendering of individual trees
            }
        }
    },
    watch: {
        treesNeeded : function(treesNeeded){
            this.animate(Math.round(treesNeeded));
        }
    },
    mounted(){
        this.animate(Math.round(this.treesNeeded));
    },
    
}
</script>

<style scoped>
    @keyframes scaleUp {
            from{transform: scale(0); visibility: visible;}
            to{ transform: scale(1); visibility:visible; }
    }

    @keyframes scale2 {
        from {
            
            transform: scale(0);
            visibility: visible;
            }
        to { 
            visibility: visible;
            transform: scale(1); 
                            }

    }
    .animate{
        visibility: hidden;
        transform-box: fill-box;
        animation-name: scaleUp;
        animation-duration: 3s;
        animation-timing-function: ease-in-out;
        transform-origin: center bottom;
        animation-fill-mode: forwards;
        
    }

    .animate-reverse {
        visibility: visible;
        transform-box: fill-box;
        animation-name: scaleUp;
        animation-duration: 3s;
        animation-timing-function: ease-in-out;
        transform-origin: center bottom;
        animation-fill-mode: forwards;
        animation-direction: reverse;
    }

    .tree {
        visibility: hidden;
    }

    .wrapper {
        padding-top:15px;
        padding-bottom: 15px;
        background-color: #e6fff9;
    }
</style>