<template>
    <footer> <!--fixed-bottom-->
            
      <div class="container">
        <div class="row">
          <div class="col-md-4">
          <span class="copyright">Copyright &copy; Sense4Future 2021</span>
        </div>
        <div class="col-md-4">
        </div>
        <div class="col-md-4">
          <ul class="list-inline quicklinks">
            <li class="list-inline-item">
              <a href="https://www.sense4future.de/datenschutz" target="_blank">Datenschutzerklärung</a>
            </li>
            <li class="list-inline-item">
              <a href="https://www.sense4future.de/impressum" target="_blank">Impressum</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

                
    </footer>
</template>

<script>
export default {
    name: 'S4FFooter'
}
</script>

<style scoped>
    footer {
  padding: 25px 0;
  background-color: #212529;
  text-align: center;
  color: #88bc57;
}

footer span.copyright {
  font-size: 90%;
  color: #88bc57;
  line-height: 40px;
  text-transform: none;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
a {
  color: #88bc57;
}

footer ul.quicklinks {
  font-size: 90%;
  line-height: 40px;
  margin-bottom: 0;
  text-transform: none;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
footer ul.quicklinks.a {
  color: #88bc57;
}

</style>>