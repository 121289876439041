export function extendedJourneyStringBuilder(extendedJourney){
    let type;
    let person;
    console.log(extendedJourney)
    switch(parseInt(extendedJourney.mode)){
        case(1):
            type = "einem PKW/Benzin";
            break;
        case(2):
            type = "einem PKW/Diesel";
            break;
        case(3):
            type = "einem PKW/Elektro";
            break;
        case(4):
            type = "einem PKW/Hybrid";
            break;
        case(5):
            type = "dem Zug";
            break;
        case(6):
            type = "dem E-Bike";
            break;
        default:
            type = "emissionsfreier Anreise"
            break
    }

    if (extendedJourney.travellers > 1){
        person = "Personen"
    }
    else{
        person = "Person"
    }
    //Tiefgestellte 2 sieht anders aus als HTML-Tag <sub>2</sub> :(
    return extendedJourney.travellers + " " + person + " mit " + type + ".\nZurückgelegte Strecke: " + extendedJourney.distance + " km.\nGeschätzte CO\u2082-Emission: " + Math.round(extendedJourney.emission * 100) /100 + "kg";
}