<template>
    <div id="partners" class="container text-center">
        <!-- <div class="row">
            <div class="col-md-12">
                Eine Kooperartion der Caritas und Sense4Future
            </div>
        </div> -->
        <div class="row justify-content-around">
            
            <div class="col align-self-center">
                <a href="https://www.sense4future.de" target="_blank"><img src="../assets/img/sense4future-logo-draft.svg"/></a>
            </div>

            <div class="col">
                <a href="https://www.hazel-arts.com/" target="_blank"><img src="../assets/img/HAZEL_Logo_final_brown_tranparent_large.png" height="130px"/></a>
            </div>
            
            <div class="col">
                <a href="https://www.caritas-dicvhildesheim.de/" target="_blank"><img src="../assets/img/Caritas_Logo_DiCV.svg" height="130px"/></a>
            </div>
           
        </div>
    </div>
</template>

<script>
export default {
    name:"Partners"
}
</script>

<style scoped>
    #partners {
        margin-top: 10px;
        padding-top: 120px;
        padding-bottom: 75px;
        margin-bottom: 10px;
    }
    .col-md-3 {
        display: flex;
        align-content: center;
    }
</style>