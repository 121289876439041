import Vue from 'vue'
import Vuex from 'vuex'

import {EmissionCalculator} from '../assets/js/emission-calcualtor'

import { adjustTime } from '../assets/js/time-shift';


Vue.use(Vuex);

console.log(EmissionCalculator)

let calculator = new EmissionCalculator();
export default new Vuex.Store({
    state:{
        startTime: adjustTime(), // Does not quite work, slicing removes timzone information, date is off by an hour
        endTime: adjustTime(),
        eventName : "",
        isMeeting: true,
        availableId: 2,
        result: {
            personalMeetingEmission: 0,
            webConferenceEmission: 0,
            activeTransportationModeEmission: 0,
            meetingMode: "", // personal || web
            travelMode: "",
        },
        meetingResultCalculated: false,
        eventResultCalculated: false,
        meetingChart: '',
        eventChart: '',
        journeys: [
            {
                id: 0,
                mode: 0,
                travellers: 1,
                distance: 1
            },
            {
                id: 1,
                mode: 3,
                travellers: 1,
                distance: 2
            }
        ]
    },
    getters: {
        journeys: state => state.journeys,
        extendedJourneys: state => state.result.extendJourneys,
        availableId: state => state.availableId,
        isMeeting: state => state.isMeeting,
        //neu
        startTime: state => state.startTime,
        endTime: state => state.endTime,
        eventName: state => state.eventName,
        getMeetingChart: (state) => state.meetingChart,
        getEventChart: state => state.eventChart,
        getMeetingResultCalculated: state => state.meetingResultCalculated,
        getEventResultCalculated: state => state.getEventResultCalculated,
        personalMeetingEmission: state => state.result.personalMeetingEmission,
        webConferenceEmission: state => state.result.webConferenceEmission,
        meetingType: state => {
            if(state.isMeeting){
                return "Event"
            }
            else {
                return "Meeting"
            }
        },
        activeTransportationModeEmission: state => state.result.activeTransportationModeEmission,
        emissionsByTransportationMode: state => state.result.emissionsByMode,
        meetingMode: state => state.result.meetingMode,
        travelMode: state => state.result.travelMode,
        treesTotalPersonal: state => state.result.personalMeetingEmission/ 10,
        treesTotalWeb: state => state.result.webConferenceEmission/ 10
        
        
    },
    mutations: {
        addJourney: state => state.journeys.push({
            id: state.availableId++,
            mode: 0,
            travellers: 1,
            distance: 1
        }),
        removeJourney: (state, id) => {
            state.journeys = state.journeys.filter(journey => journey.id !== id)
        },
        toggleMeeting: state => {
            state.isMeeting = !state.isMeeting
        },
        calculateMeetingResult: (state, payload) => {
            state.result = payload;
            state.result.meetingMode = 'personal',
            state.result.travelMode = null
            state.meetingResultCalculated = true;
            console.log("Result Calcualted")
        },
        extendJourneys: (state, payload) => state.extendedJourney = payload,
        duration : (state) => {
            const result = (new Date(state.endTime) - new Date(state.startTime)) / 1000 / 3600
            state.duration = result
        },
        setStartTime: (state, payload) => state.startTime = adjustTime(payload),
        setEndTime: (state, payload) => state.endTime = adjustTime(payload),
        setEventName: (state, payload) => state.eventName = payload,
        addChart: (state, payload) => state[payload.chartName] = payload.chart, // updated to add a given chart

        // TODO: update the right chart
        updateChart: (state, chartName) => {
            console.log("MEEEH",state[chartName])
            state.result.emissionsByMode.sort((a,b) => a.emission-b.emission)
            state.result.emissionsByMode.reverse()
            state[chartName].data = {
                labels: ['Persönliches Anreise', 'Videokonferenz'],
                datasets: []
            }
            for(let i = 0; i < state.result.emissionsByMode.length; i++){
                if(state.result.emissionsByMode[i].emission === 0){
                    continue;
                }
                // TODO: Determine which color to used
                //let color = `rgba(${Math.round(Math.random() * 255)},${Math.round(Math.random() * 255)},${Math.round(Math.random() * 255)})`
                state[chartName].data.datasets.push({
                    label: calculator.mapTravelModeToString(state.result.emissionsByMode[i].label),
                    backgroundColor: calculator.color()[state.result.emissionsByMode[i].label], //temporary, to assign different colors. Replace with fixed colors for each mode
                    data: [
                        state.result.emissionsByMode[i].emission,
                        0
                    ]
                })
            }
            state[chartName].data.datasets.push(
                {
                    label: 'Videokonferenz',
                    data: [0, state.result.webConferenceEmission],
                    backgroundColor: calculator.color().Videoconference
                }
            )
            //state[chartName].data.datasets[0].data = [state.result.personalMeetingEmission, state.result.webConferenceEmission]
            state[chartName].update()
        },
        activeEmissionMode: (state, payload) => state.result.activeTransportationModeEmission = payload,
        setMeetingMode: (state,payload) => state.result = {...state.result, meetingMode: payload},
        setTravelMode: (state, payload) => state.result.travelMode = payload
    },
    actions: {
        addJourney: state => state.commit('addJourney'), //adds a new journey to the array of journeys
        removeJourney: (state, payload) => state.commit('removeJourney', payload), // removes a journey from the list, payload specifies the id of the journey
        toggleMeeting: state => { //Changes state between meeting and event
            state.commit('toggleMeeting')
        },
        // Works as intended, but not needed
        //Todo: calculate a proper result
        //Todo: create a script of helper-functions for calculating a result
        calculateMeetingResult: (state, payload) => {
            console.log("paaaayload",payload.chartName, typeof payload.chartName)
            let converted = state.getters.journeys.map(journey =>{
                return {
                    id: parseInt(journey.id),
                    mode: parseInt(journey.mode),
                    travellers: parseInt(journey.travellers),
                    distance: parseFloat(journey.distance)
                } 
            })

            let duration = (new Date(state.getters.endTime) - new Date(state.getters.startTime))/1000/3600
            let extendedJourneys = calculator.extendJourneysWithEmission(converted);

            let emissionsByTransportationMode = calculator.calculateEmissionByJourneyType(extendedJourneys)
            
            let personalMeetingEmission = calculator.sumEmissionsOfJourneys(extendedJourneys)

            let participants = calculator.sumTravellers(extendedJourneys)

            let webConferenceEmission = calculator.calculateWebconferenceEmission(participants, duration) //duration has to be calculated
            
            state.commit('calculateMeetingResult',{extendedJourneys: extendedJourneys, duration: duration, participants: participants, personalMeetingEmission:personalMeetingEmission, webConferenceEmission:webConferenceEmission, emissionsByMode: emissionsByTransportationMode, activeTransportationModeEmission: personalMeetingEmission});
            // barely works, messy AF
            if(state.getters.getMeetingChart !== ''){
                console.log("updating");
                state.commit('updateChart', 'meetingChart')
            }    
        },
        activeEmissionMode: (state, payload) => state.commit('activeEmissionMode', payload),
        // not needed
        extendJourneys: (state, payload) => {
            let extendedJourneys = calculator.extendJourneysWithEmission(payload);
            state.commit('extendJourneys', extendedJourneys)
            state.commit('duration')
        },
        addChart: (state,payload ) => state.commit('addChart', payload),
        setMeetingModePersonal: (state,payload) => {
            state.commit('setMeetingMode', payload.meetingMode);
            state.commit('setTravelMode', payload.travelMode);
        },
        setMeetingModeWeb: (state) => {
            state.commit('setMeetingMode', 'web');
            state.commit('setTravelMode', null);
        }
    }
});