import { extendedJourneyStringBuilder}  from './string-builder'
export class EmissionCalculator{
    /*
        Function to calculate the emission of a given journey
        INPUT: a single journey object {id:x, mode:y, travellers: z, distance: n}
        OUTPUT: the carbon-dioxide emission for the given journey as numeric value in kg
    */
    calculateEmissionForJourney(journey){
        
        let emission

        switch(parseInt(journey.mode)){ //parse mode because it is a string for whatever reason
            case 1: //PKW / Benzin
                emission = 0.161 * journey.distance; // 2,3 kg of CO2 per liter at 7l per 100km: (2,3*7)/100 = 0,161kg per km
                break;
            case 2: // PKW / Diesel
                emission = 0.182 * journey.distance; // 2.6kg of CO2 per l at 7l per 100km: (2.6*7)/100 = 0.182kg per km
                break;
            case 3: // PKW / Elektro
                emission = 0.06015 * journey.distance; // ~15kWh/100km at 401g CO2 per kWh = 0.06015g per km
                break;
            case 4: // PKW / Hybrid
                emission = 0.175 * journey.distance;
                break;
            case 5: // Zug
                emission = 0.041 * journey.distance  * journey.travellers;
                break;
            case 6: //Flugzeug
                emission = 0.2 * journey.distance * journey.travellers;
                break;
            default:
                emission = 0;
                break;
        }

        return emission
    }

    /*
        Function to sum emissions by mode of transportation
        INPUT: an object containing extended journey, meaning journeys have emissions attached
        OUTPUT: an array containing the summed up emission for every mode of transportation used
    */
    calculateEmissionByJourneyType(extendedJourneys){
        let labels = []
        let emissions = []

        let objectArray = []

        Object.keys(extendedJourneys).forEach(key => {
            let indexOfElement = labels.indexOf(extendedJourneys[key].mode)
            //console.log(indexOfElement)
            if(indexOfElement === -1){
                labels.push(extendedJourneys[key].mode)
                emissions.push(extendedJourneys[key].emission)
            }
            else{
                emissions[indexOfElement] += extendedJourneys[key].emission;
            }
        });
        for (let i = 0; i < emissions.length; i++){
            //console.log("TEST LOOP")
            objectArray.push({emission: emissions[i], label: labels[i]})
        }

        objectArray.sort((a,b)=> (a.emission - b.emisison))

        objectArray.reverse()

        //console.log('sorted: ', objectArray)
        //return {emissions: emissions, labels: labels}
        return objectArray
    }

    /*
        Function to extend a given journey by its calculated emission
        INPUT: a single journey object {id: x, mode: y, travellers: z, distance: n}
        OUTPUT: a single journey object, extended with the calculateted emission for the given journey
        {id: x, mode: y, travellers: z, distance: n, emission: m}
    */
    mapEmissionToJourney(journey){
        let extendedJourney =  {
            id: journey.id,
            mode: journey.mode,
            travellers: journey.travellers,
            distance: journey.distance,
            emission: this.calculateEmissionForJourney(journey)
        }

        extendedJourney.string = extendedJourneyStringBuilder(extendedJourney)
        console.log("mode", extendedJourney.mode)
        return extendedJourney;
    }
    /*
        Function to extend everey journey in an array of journeys by their respective emission
        INPUT: An array of journey objects [{id:x1, mode:y1, travellers:z1, distance:n1},..., {id:xn, mode:yn, travellers:zn, distance:nn}]
        OUTPUT: An array of journey objects extended with their emission [{id:x1, mode:y1, travellers:z1, distance:n1, emission: m1},..., {id:xn, mode:yn, travellers:zn, distance:nn, emission: mn}]
    */
    extendJourneysWithEmission(journeys){
        let extendedJourneys = journeys.map(journey => this.mapEmissionToJourney(journey));

        return extendedJourneys;
    }
    /*
        Function, that calculates the total emission for all Journeys
        INPUT: an array of journeys, extended with their resprective emissions [{id, mode, travelleres, emission},..., {id, mode, travellers, emission}]
        OUTPUT: the sum of all emissions in kg 
    */
    sumEmissionsOfJourneys(extendedJourneys){
        return extendedJourneys.reduce((accumulator, current) => accumulator += parseFloat(current.emission), 0)
    }

    /*
        A function that calculates the sum of travellers for all journeys
        INPUT: An array of journeys
        OUTPUT: the sum of travellers
    */
    sumTravellers(journeys){
        return journeys.reduce((accumulator, current) => accumulator += parseInt(current.travellers),0)
    }

    /*
        Function, that calculates the emission of a webconference for a given number of participants and duration
        INPUT: allTrevellers, the number of participants, duration, the duration of the webconference in hours
        OUTPUT: The carbon dioxide emission of a webconference with the given number of participants and duration in kg
    */
    calculateWebconferenceEmission(allTravellers, duration){
        return allTravellers * 0.2 * 0.401 * duration // participants * average kw/h per PC * grams of C02 per kw/h * duration of the conference
    }
    mapTravelModeToString(travelMode){
        switch(travelMode){
            case 0:
                return "Emissionsfrei";
                
            case 1:
                return "PKW/Benzin";
            
            case 2:
                return "PKW/Diesel";

            case 3:
                return "PKW/Elektro";
            
            case 4:
                return "PKW/Hybrid";
            
            case 5:
                return "Zug";

            case 6:
                return "E-Bike";
        }
    }

    color(){
        return {
            Videoconference: '#A6D59F',
            '6': '#B7C06F',
            '5': '#6FC073',
            '1': '#A4845E',
            '2': '#906731',
            '3': '#70C0A5',
            '4': '#6FB1C1'

        }
    }
}