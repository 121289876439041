<template>
  <div class="col col-xs-12 col-sm-12 col-md-6 col-lg-6 mx-auto">
    <h2> Persönliches Meeting - oder doch lieber Videokonferenz?</h2>
    <h3><i> Was ist nachhaltiger?</i></h3>
    <p>Nutzen Sie unseren CO<sub>2</sub>-Rechner um es herauszufinden!</p>
    <ul>
      <li>
        Geben Sie den Namen des Meetings; sowie die geplante Dauer ein.
      </li>
      <li>
        Der CO<sub>2</sub>-Ausstoß der Videokonferenz wird automatisch aus der Dauer ermittelt.
      </li>
    </ul>
    <br/>
    <p>
      Um den CO<sub>2</sub>-Ausstoß der Dienstreise zu ermitteln brauchen wir noch einige Angaben.
    </p>
    <ul>
      <li>
        Wählen Sie das Fortbewegungsmittel, die Teilnehmer pro Fahrzeug und die ungefähr benötigte Strecke.
      </li>
      <li>
        Geben Sie die benötigte Strecke bitte inklusive Rückfahrt an.
      </li>
      <li>
        Mehr Felder benötigt? Nutzen Sie das <strong>+</strong>.
      </li>
    </ul>
    <!-- <p>
      Duch einen Klick auf die Schaltfläche "Ergebnis" werden CO<sub>2</sub>-Emissionen für alle Anreisearten sowie eine Webkonfernz mit der durch Start- und Endzeit festgelegten Dauer berechnet. Die Anzahl der Teilnehmer wird dabei aus der Summe der Anreisenden berechnet.
    </p> -->
  </div>
</template>

<script>
export default {
  name: "Erklaerung"

}
</script>

<style scoped>
  div{
    padding-top:35px;
  }
  h2{
    margin-bottom:20px;
  }
  h3{
    margin-bottom:20px;
  }
</style>