<template>
  <div id="app">
    <!-- <S4FHeader/> -->
    <Masthead />
    <!-- Dynamicaly genreated content -->
    <!-- <b-tabs content-class="mt-3" fill :active-nav-item-class="'active-tab'">
      <b-tab title="Meeting" > -->
        <Title/>
        <div class="container" style="margin-bottom:30px;"> <!--style="margin-top:100px;"-->
          <div class="row" v-if="!this.$store.state.meetingResultCalculated">
            <div class="container">
              <div class="row">
                 <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mx-auto" >
                  <Meeting/>
                </div>
                <Erklaerung/>
              </div>
            </div>
          </div>
          <div class="row" v-else>
            
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mx-auto" >
              <Meeting/>
            </div>
            <div v-if="this.$store.state.meetingResultCalculated" class="col col-xs-12 col-sm-12 col-md-6 col-lg-6 mx-auto">
              <ResultChart chartId="meetingChart"/>
            </div>
           
            <!--<div v-else class="col col-xs-12 col-sm-12 col-md-6 col-lg-6 mx-auto" style="margin-top:30px;">
              <h2 style="margin-top:15px;">Hier kommt die Erklärung</h2>
              <p>An dieser Stelle wird erklärt, dass die WEbseite zum Vergleich der CO<sub>2</sub> Fußabdrücke von persönlichen Meetings und Webkonferenzen gedacht ist.</p>
              <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eum dolorum magni illo maxime consequatur eos earum voluptas sequi deserunt cumque omnis, veniam molestiae aut perferendis atque similique officia eligendi vitae?</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis ea tenetur optio nam reiciendis rem? Velit, eius amet maiores ea ab fuga veniam porro molestiae ratione corporis iusto eaque temporibus?</p>
            </div>-->
            
          </div>
        </div>
        <Trees v-if="this.$store.state.meetingResultCalculated" />
        <StringResult v-if="this.$store.state.meetingResultCalculated"/>
        <FooterResult v-if="this.$store.state.meetingResultCalculated" />
          
      <!-- </b-tab>
      <b-tab title="Großveranstaltung">
        <Title />
        <div class="container">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mx-auto">
              <Event/>
            </div>
          </div>
        </div>
        <div id="flex-container">
          
          <div v-if="this.$store.state.eventResultCalculated">
            <ResultChart chartId="eventChart"/>
            <StringResult/>
          </div>
        </div>
      </b-tab>
    </b-tabs> -->
    
     <Explain/>
     <Partners/>
     <S4FFooter/>
  </div>
 
</template>

<script>
import Meeting from './components/Meeting.vue';
// import Event from './components/Event.vue';
import ResultChart from './components/ResultChart.vue';
import StringResult from './components/StringResult.vue';
// import S4FHeader from './components/S4FHeader.vue';
import S4FFooter from './components/S4FFooter.vue';
import Masthead from './components/Masthead';
import Explain from './components/Explain';
import Partners from './components/Partners';
import Trees from './components/Trees';
import FooterResult from './components/FootResult';
import Erklaerung from './components/Erklaerung';
import Title from './components/Title'
export default {
  name: 'App',
  components: {
    // Event,
    Meeting,
    ResultChart,
    StringResult,
    // S4FHeader,
    S4FFooter,
    Masthead,
    Explain,
    Partners,
    Trees,
    FooterResult,
    Erklaerung,
    Title
  },
  methods: {
    toggleMeeting: function(){
      this.$store.dispatch('toggleMeeting')
    },
    // aus string result
    
  },
  computed: {
    meetingType(){
       return this.$store.getters.meetingType
    }
  }
}
</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  /* font-family: Georgia, 'Times New Roman', Times, serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  
}

.nav-item {
  font-size:x-large;
  /* font-weight: bold; */
}

.nav-item:not(.active){
  background-color: #FDFFFE;
}
.nav-link {
  
  color: #aaaaaa;
}
  .active-tab {
    color: #6FB1C1 !important;
}

.visualization {
  margin: 10px 0px
}
.clickable{
  cursor:pointer;
  padding: 10px;
}

</style>
