<template>
    <div id="chart-container">
        <canvas :id="this.chartId" width="100" height="0"></canvas>
    </div>
</template>

<script>
import { drawChart } from '../assets/js/chart-logic';
export default {
    name: 'ResultChart',
    props:['chartId'],
    mounted(){
        let web = this.$store.state.result.webConferenceEmission;

        let personal = this.$store.state.result.emissionsByMode;
        let myChart = drawChart(personal,web, this.chartId); // Check if function works, use emissions from state

        console.log(myChart)
        console.log(this.chartId)
        this.$store.dispatch({
            type: 'addChart',
            chartName: this.chartId,
            chart: myChart
        }); // TODO: add a chart for both charts

        this.$store.commit('updateChart', this.chartId)
    }
}
</script>

<style scoped>
    div#chart-container {
        padding-top:30px;
        min-height:470px;
        width: 100%;
        height: 95%;
    }

    
        
</style>